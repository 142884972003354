body {
  margin: 0;
  background-color: #1D1D1D;
}

@font-face {
  font-family: 'georgia-regular';
  src: local('georgia-regular'),
    url(./assets/fonts/georgia-regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "montserrat";
  src: local("montserrat"), url(./assets/fonts/Montserrat-SemiBold.ttf) format("truetype");
  font-display: swap;
}