.video-element {
  height: 100vh;
  width: 100vw;
  min-height: 100%;
  min-width: 100%;
  margin: 0 auto;
}

.qr-close-btn {
  /* background-color: transparent;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px; */
  z-index: 10;
}

.qr-close-btn img {
  max-width: 50px;
}

.qr-close-btn:hover {
  cursor: pointer;
}

.scan-region-highlight-svg {
  stroke: #fff !important;
}

.scanner-holder {
  width: 100vw !important;
  height: 100vh !important;
}

.scanner-holder .qr-reader section div {
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  border: none !important;
  box-shadow: white 0px 0px 0px 5px inset !important;
  width: 400px !important;
  height: 400px !important;
}

.scanner-holder .qr-reader section {
  position: static !important;
  padding: 0 !important;
}

.qr-close-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (max-width:800px) {
  .scanner-holder .qr-reader section div {
    width: 200px !important;
    height: 200px !important;
  }
}