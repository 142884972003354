.App {
  text-align: center;
}

:root {
  --base-gold-color: #CBAC6E;
  --dark-gold-color: #65532F;
}

.page-title {
  font-size: 32px;
  line-height: 34px;
  font-weight: 400;
}

p {
  color: #fff;
}
.single-card p:first-of-type{
  color: #cbac6e!important;
}
.single-card p:nth-child(2){
  line-height: 22px;
}
.main-button {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  border: 2px solid var(--base-gold-color);
  border-radius: 25px;
  color: #fff;
  width: 140px;
  height: 50px;
  font-family: "montserrat";
  white-space: nowrap;
  line-height: 50px;  
  margin-bottom: 40px;
}