body {
  margin: 0;
  /* height: 100vh; */
  height: 100%;
  width: 100%;
  background-image: url("../assets/images/lobkowicz-badge.png");
  background-position: top center;
  background-repeat: no-repeat;
  font-family: "georgia-regular";
}


#root {
  margin: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  font-family: "georgia-regular";
}

.lb-home {
  height: 100vh;
  // background-image: url("../assets/images/lobkowicz-badge.png");
  // background-position: top center;
  // background-repeat: no-repeat;
  // background-size: cover;
}

.header-section {
  img {
    padding-top: 80px;
    max-width: 130px;
  }
}

.lb-content-section {
  padding-top: 30px;
}

.lb-content-section {
  p {
    font-size: 20px;
    line-height: 24px;
    color: var(--base-gold-color);
  }
}

.lb-footer {
  display: flex;
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 10px;
  max-width: 350px;
  margin: 0 auto;
  justify-content: center;
  gap: 16px;

  :hover {
    cursor: pointer;
  }
}

.address-input {
  width: 80%;
  background-color: var(--dark-gold-color);
  border: none;
  border-radius: 0%;
  padding: 15px 20px;
  color: #fff;
  font-size: 12px;
  max-width: 330px;
  font-family: "georgia-regular";
}

.address-input:focus-visible {
  outline: none;
}

@media screen and (max-width: 800px) {
  #root {
    text-align: center;
  }
}